#homepage .talents img {
    filter: grayscale(100%);
    transition: 200ms;
}

#homepage .talents img:hover {
    filter: grayscale(0);
}

#homepage #reviews .card {
    min-width: 50%;
    min-height: 286px;
}
/* FOR THE VIDEO */
.video-wrapper {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  


@media (max-width: 1399px) {
    #homepage #reviews .card {
        min-height: 358px;
    }
    
}

@media (max-width: 1199px) {
    #homepage #reviews .card {
        min-height: 286px;
    }
    
}

@media (max-width: 991px) {
    #homepage #reviews .card {
        min-height: 358px;
    }
    
}

@media (max-width: 767px) {
    #homepage #reviews .card {
        min-height: 257px;
    }
}

@media (max-width: 575px) {
    #homepage #reviews .card {
        min-height: 286px;
    }
}

@media (max-width: 431px) {
    #homepage #reviews .card {
        min-height: 302px;
    }
   
}

@media (max-width: 403px) {
    #homepage #reviews .card {
        min-height: 358px;
    }
   
}

@media (max-width: 357px) {
    #homepage #reviews .card {
        min-height: 400px;
    }
}

@media (max-width: 346px) {
    #homepage #reviews .card {
        min-height: 450px;
    }
}

.instructor__thumb-two {
    width: auto;
    flex: 0 0 auto;
    margin-top: auto;
}

svg.injectable {
    width: 224px;
    height: 158px;
    fill: none;
}

.instructor__thumb-two .shape-one {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    color: var(--bs-secondary);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.instructor__content-two p {
    margin-bottom: 20px;
}

.instructor__thumb-two .shape-two .svg-icon {
    width: 100%;
    height: 100%;
    display: block;
}

.instructor__area-four {
    background: var(--bs-gray);
}

.tg-button-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.instructor__item-two {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    gap: 30px;
    align-items: center;
    border: 1px solid #BDBDBD;
    background: var(--bs-white);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    /*-o-border-radius: 16px;*/
    /*ms-border-radius: 16px;*/
    border-radius: 16px;
    padding: 0 60px 0 25px;
    position: relative;
    z-index: 3;
    overflow: hidden;
    min-height: 260px;
    margin-bottom: 30px;
}
