.cardImg {
    width: 100%;
    height: 250px; /* Set a fixed height for consistency */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .cardImg img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures images fill the space without distortion */
  }
  